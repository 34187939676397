* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}

body {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow: hidden;
  background-color: #ccc;
}

body #Header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #b3a369;
  width: 100vw;
  padding: .55%;
}

body #Header .Gt-Header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

body #Header .Gt-Header .Gt-logo {
  width: 8%;
}

body #Header .Gt-Header .customer-name {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 68%;
          flex: 0 1 68%;
  padding-left: 2%;
}

body #Header .Gt-Header div {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 10%;
          flex: 0 1 10%;
  text-align: right;
}

body .Slideshow {
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: 80vh;
}

body .Slideshow li {
  list-style: none;
  background-color: aqua;
  height: 100%;
}

body .Slideshow .Client-content {
  height: 100%;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 77%;
          flex: 0 1 77%;
  margin-right: .5vw;
  overflow: hidden;
  -webkit-transition: ease 1000ms;
  transition: ease 1000ms;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: relative;
}

body .Slideshow .Client-content div {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: inline-block;
}

body .Slideshow .Client-content div img {
  min-width: 100%;
  min-height: 100%;
  display: inline-block;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

body .Slideshow .Client-content div.activeSlide {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

body .Slideshow .Client-content div.nextSlide {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

body .Slideshow .Client-content div.lastSlide {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

body .Slideshow .Events-content {
  height: 100%;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 22.8%;
          flex: 0 1 22.8%;
  overflow: hidden;
  -webkit-transition: ease 1000ms;
  transition: ease 1000ms;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: relative;
}

body .Slideshow .Events-content div {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  display: inline-block;
}

body .Slideshow .Events-content div img {
  max-height: 100%;
  display: inline-block;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

body .Slideshow .Events-content div.activeSlide {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

body .Slideshow .Events-content div.nextSlide {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

body .Slideshow .Events-content div.lastSlide {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

body .Footer {
  width: 100vw;
  height: 8%;
  background-color: #b3a369;
}

body .Footer .GT-Footer {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

body .Footer .GT-Footer ul {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 95%;
          flex: 0 1 95%;
  overflow: hidden;
}

body .Footer .GT-Footer ul li {
  list-style: none;
  height: 100%;
  display: inline-block;
  margin: 0 2vw;
}
