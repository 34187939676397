*{
    margin: 0;
    padding: 0;
    box-sizing: content-box;

}

body{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    overflow: hidden;
    background-color: #ccc;
   
    #Header{
        align-items: center;
        background-color: #b3a369;
        width: 100vw;
        padding: .55%;

        .Gt-Header{
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;

            .Gt-logo{
                width: 8%;
            }
            .customer-name {
                flex: 0 1 68%;
                padding-left: 2%;
            }
            div {
                flex: 0 1 10%;
                text-align: right;

            }
        }
    }

    .Slideshow{
        width: 100vw;
        display: flex;
        flex-direction: row;        
        height: 80vh;    
            li{
                list-style: none;
                background-color: aqua;
                height: 100%;
            }
            .Client-content{
                height: 100%;
                flex: 0 1 77%;
                margin-right: .5vw;
                overflow: hidden;
                transition: ease 1000ms;
                display: flex;
                flex-direction: row;
                position: relative;


                div {
                    position:absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    transition: all 0.3s linear;
                    display: inline-block;
                    // overflow: hidden;
                    

                    img {
                        min-width: 100%;
                        min-height: 100%;
                        display: inline-block;
                        flex-shrink: 0;
                    }

                    &.activeSlide {
                        opacity: 1;
                        transform: translateX(0);
                    }

                    &.nextSlide {
                        transform: translateX(-100%);
                    }

                    &.lastSlide {
                        transform: translateX(100%);
                    }
                }
                
            }
            .Events-content{
                height: 100%;
                flex: 0 1 22.8%;
                overflow: hidden;
                transition: ease 1000ms;
                display: flex;
                flex-direction: row;
                position: relative;

                div {
                    position:absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    transition: all 0.3s linear;
                    display: inline-block;
                    // overflow: hidden;
                    

                    img {
                        // min-width: 100%;
                        max-height: 100%;
                        display: inline-block;
                        flex-shrink: 0;
                    }

                    &.activeSlide {
                        opacity: 1;
                        transform: translateX(0);
                    }

                    &.nextSlide {
                        transform: translateX(-100%);
                    }

                    &.lastSlide {
                        transform: translateX(100%);
                    }
                }

                // .events-date{
                //     background: #b3a369;
                //     padding: 1.2vh;
                //     max-width: 30%;
                //     text-align: center;
                //     padding-bottom: 2vh;
                //     border-bottom-right-radius: 1vh;
                // }
                
                // .event-slide{
                //     display: flex;
                //     flex-direction: column;
                //     justify-content: center;
                    
                //     ul{
                //         text-align: center;
                //         .event{
                //             padding: 1vh 0;
                //             margin: 0 2vw;

                //             &-title{
                //                 font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
                //             }

                //             &-description{
                //                 margin: 1vh 0;
                //                 height: 4vh;
                //                 overflow: hidden;
                //             }

                //             &-details{
                //                 background: #EAAA00;
                //                 padding: 1vh;
                //                 max-height: 2vh;
                //                 overflow: hidden;
                //                 border-radius: 0 0 1vh 1vh;            
                //             }
                //         }
                //     }
                // }
            }      
    }

    .Footer{
        width: 100vw;
        height: 8%;
        background-color: #b3a369;
        .GT-Footer{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content:flex-start;
            align-items: center;
            ul{
                flex: 0 1 95%;
                overflow: hidden;
                li{
                    list-style: none;
                    height: 100%;
                    display: inline-block;
                    margin: 0 2vw; 
                }
            }
        }
    }

}

